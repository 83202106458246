if ($('.sfc-hero').length) {
    $(window).on('load resize', function () {
        resizeImageBackdrop();
    });
    function resizeImageBackdrop() {
        $('.sfc-hero').each(function () {
            if ($(this).find('.img-overlay')) {
                $(this).closest('.js-setContentHeight').css('min-height', '0');
                let overlayHeight = $(this).find('.img-overlay').outerHeight()
                let setHeight = overlayHeight + 120;
                $(this).closest('.js-setContentHeight').css('min-height', setHeight);

                let imgFind = $(this).find('.bg-img > img');
                $(this).find('.bg-img > img').css(
                    {
                        'height':setHeight,
                        'object-fit':'cover'
                    }

                );
            }
        });
    }
}