var catFilterItems = $('#blog-filter .btn-filter:not(.btn-filter-all'),
    allCatFilter = $('#blog-filter .btn-filter.btn-filter-all'),
    resetFilters = $('#blog-filter .btn-reset'),
    paginationHolder = $('#blog-pagination');

catFilterItems.on('click', function(e) {
    e.preventDefault();
    if ($(this).hasClass('is-active')) {
        $(this).removeClass('is-active');
    } else {
        allCatFilter.removeClass('is-active');
        $(this).addClass('is-active');
    }
    gatherVars();
});

resetFilters.on('click', function() {
    filterReset();
});
allCatFilter.on('click', function() {
    filterReset();
});

paginationHolder.on('click', 'a', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-disabled')) {
        page = $(this).data('p');
        gatherVars(page);
    }
});

function filterReset() {
    catFilterItems.each(function() {
        $(this).removeClass('is-active');
    });
    allCatFilter.addClass('is-active');

    gatherVars();
}


function gatherVars(page = 1) {
    var category = [];
    catFilterItems.each(function() {
        if ($(this).hasClass('is-active')) {
            category.push($(this).data('id'));
        }
    });
    if (allCatFilter.hasClass('is-active')) {
        category.push('all');
    }
    if (category.length == 0) {
        allCatFilter.addClass('is-active');
        category.push('all');

    }
    triggerFilter(category, page);
}

function triggerFilter(category = "", page = 1) {
    $.post('/wp-admin/admin-ajax.php', {
        action: "post_filter",
        category: category,
        page: page,
    }).done(function(r) {

        if (r.success) {
            $('#filter-results').empty();
            // console.log(r);
            var posts = r.data.posts,
                pagination = r.data.pagination;

            for (var i = 0; i < posts.length; i++) {
                var post = posts[i];
                var html = '<article class="col-sm-6 col-xxl-4">';
                html += '<a href="' + post.permalink + '" class="card-article">';
                html += '<div class="img-container">';
                html += post.featuredImage;
                html += '</div>';
                html += '<div class="card-body">';
                html += '<div class="card-content">';
                html += '<span class="heading-6 color-blue-light mb-3">';
                html += post.category;
                html += '</span>';
                html += '<h3 class="card-title color-black mb-3">';
                html += post.title;
                html += '</h3>';
                html += '<span class="article-details">';
                html += post.date;
                if(post.readTime !== null) {
                    html += '<span class="separator" role="presentation">//</span>';
                    html += post.readTime + ' min read';
                }
                html += '</span>';
                html += '</div>';
                html += '</div>';
                html += '</a>';
                html += '</article>';

                $('#filter-results').append(html);
            }
            paginationHolder.html(pagination);

        } else {
            // console.log(r);
            var pagination = r.data.pagination;
            $('#filter-results').html('<div class="no-posts col-12"><h2>No posts found</h2></div>');
            paginationHolder.html(pagination);
        }
    });

}


// siouxfalls.com Job Filters
var industryFilter = $('#sf-jobs-filter #jobIndustry'),
    jobsReset = $('#sf-jobs-filter #jobsReset'),
    jobsLoadMore = $('.load-jobs');

industryFilter.on('change', function(e) {
    e.preventDefault();

    var selectedTerm = $(this).val().toString(),
        label = $(this).find('option:selected').text().toLowerCase(),
        $container = $(this).closest('.job-listing-display');

    // update url the append the selectedTerm as an anchor
    var url = new URL(window.location.href);
    url.hash = encodeURIComponent(label);
    window.history.pushState({}, '', url);

    $container.find('.card-job').each(function() {
        var row = $(this).parent(),
            terms = $(this).data('terms').toString();

        if(terms !== '' && terms.indexOf(selectedTerm) > -1) {
            row.removeClass('d-none');
        } else {
            row.addClass('d-none');
        }
    });

    $container.find('.load-jobs').remove();
});


jobsReset.add(jobsLoadMore).on('click', function(e) {
    e.preventDefault();

    var $container = $(this).closest('.job-listing-display');

    $container.find('.row .col-md-6').each(function() {
        $(this).removeClass('d-none');
    });

    industryFilter.val('');

    // remove the hash from the url
    var url = new URL(window.location.href);
    url.hash = '';
    window.history.pushState({}, '', url);

    $container.find('.load-jobs').remove();
});

// on page ready check if there is a hash and if so, trigger the change event
$(document).ready(function() {
    if(industryFilter.length === 0) return;

    if(window.location.hash) {
        var hash = window.location.hash.substring(1);
        // update the dropdown matching the hash to the select option text
        industryFilter.find('option').each(function() {
            if($(this).text().toLowerCase() === decodeURIComponent(hash)) {
                $(this).prop('selected', true);
                // trigger the change event
                industryFilter.trigger('change');
            }
        });
    }
});