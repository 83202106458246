// SF Chamber Blog Listing Filtering
var blogCatFilter = $('#forward-blog-filters #blogCat'),
    // blogIssueFilter = $('#blog-filters #blogIssue'),
    resetFilters = $('#forward-blog-filters #blogReset'),
    paginationHolder = $('#forward-blog-pagination');

blogCatFilter.on('change', function() {
    gatherVars();
});

// blogIssueFilter.on('change', function() {
//     gatherVars();
// })

resetFilters.on('click', function() {
    filterReset();
});

paginationHolder.on('click', 'a', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-disabled')) {
        page = $(this).data('p');
        gatherVars(page);
    }
});

function filterReset() {
    blogCatFilter.val('');
    // blogIssueFilter.val('');

    gatherVars();
}


function gatherVars(page = 1) {
    var cat, issue;
    cat = blogCatFilter.find('option:selected').data('id');
    // issue = blogIssueFilter.find('option:selected').data('id');
    if(!cat) {
        cat = [];
        blogCatFilter.find('option').each(function() {
            cat.push($(this).data('id'));
        });
    }

    triggerFilter(cat, page);
}

if($('#forward-blog-filters').length) {
    gatherVars();
}

function triggerFilter(cat, page = 1) {
    $.post('/wp-admin/admin-ajax.php', {
        action: "blog_filter",
        cat: cat,
        page: page,
    }).done(function(r) {

        if (r.success) {
            $('#forward-blog-filter-results').empty();
            // console.log(r);
            var posts = r.data.posts,
                pagination = r.data.pagination;

                for (var i = 0; i < posts.length; i++) {
                    var post = posts[i];
                    var html = '<article class="col-sm-6 col-xxl-4">';
                    html += '<a href="' + post.permalink + '" class="card-article">';
                    html += '<div class="img-container has-bg-overlay is-red">';
                    html += post.featuredImage;
                    html += '<span class="more-btn">Read More</span>';
                    html += '</div>';
                    html += '<div class="card-body">';
                    html += '<div class="card-content">';
                    html += '<span class="heading-6 color-red mb-3">';
                    html += post.category;
                    html += '</span>';
                    html += '<h3 class="card-title color-black mb-3">';
                    html += post.title;
                    html += '</h3>';
                    html += '<span class="article-details">';
                    html += post.date;
                    // console.log(post.readTime);
                    if(post.readTime !== null && post.readTime > 0) {
                        html += '<span class="separator" role="presentation">//</span>';
                        html += post.readTime + ' min read';
                    }
                    html += '</span>';
                    html += '</div>';
                    html += '</div>';
                    html += '</a>';
                    html += '</article>';
    
                    $('#forward-blog-filter-results').append(html);
                }
            paginationHolder.html(pagination);
        } else {
            // console.log(r);
            var pagination = r.data.pagination;
            $('#forward-blog-filter-results').html('<div class="no-posts col-12"><h2>No posts found</h2></div>');
            paginationHolder.html(pagination);
        }
    });

}