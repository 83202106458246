(function($) {
    var primaryNavigation = $('#primaryNav');
    var siteWrapper = $('.wrapper');
    var navbarPrimary = '.nav-primary';

    var primarySearch = $('#primarySearch');

    // Open navigation overlay
    $('#primaryNavbar #navbarToggle').on('click', function() {
        primaryNavigation.addClass('is-active');
        siteWrapper.addClass('overlay-open-left');
        $('body').addClass('no-scroll');
    });

    // Opens submenu
    $('.submenu .nav-link').on('click', function() {
        $this = $(this);
        submenuTarget = $this.attr('data-target');

        $this.closest(navbarPrimary).addClass('collapsed');

        $(submenuTarget).addClass('is-open');
    });

    // Collapse submenu overlay
    $('.js-overlay-back').on('click', function() {
        $(this)
            .closest('.overlay-submenu')
            .removeClass('is-open');

        $(navbarPrimary).removeClass('collapsed');
    });

    // Close navigation overlay
    $('.js-overlay-close').on('click', function() {
        primaryNavigation.removeClass('is-active');
        primarySearch.removeClass('is-active');
        siteWrapper.removeClass('overlay-open-left').removeClass('overlay-open-right');
        $(navbarPrimary).removeClass('collapsed');
        $('.overlay-submenu').removeClass('is-open');
        $('body').removeClass('no-scroll');
    });

    // Opens search overlay
    $('#searchToggle').on('click', function(e) {
        e.preventDefault();
        primarySearch.addClass('is-active');
        siteWrapper.addClass('overlay-open-right');
        $('body').addClass('no-scroll');

        if ($(window).width() >= 768) {
            $('.form-search .form-control').focus();
        }
    });

}(jQuery));