(function($) {

$('.js-tab-prev').click(function () {
    $('.nav-tabs > .nav-item > .active').parent().next('li').find('a').trigger('click');
});

$('.js-tab-nextx').click(function () {
    $('.nav-tabs > .nav-item > .active').parent().prev('li').find('a').trigger('click');
});

$('.nav-tabs .nav-item').on('click', function() {
    $(this).parents('.tab-block').find('.video').each(function() {
        this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
    });
});

})(jQuery);

/*Initialize responsive tabs*/
$(function() {
    $('.responsive-tabs').responsiveTabs();
});