// SF Chamber Blog Listing Filtering
var blogCatFilter = $('#blog-filters #blogCat'),
    blogIssueFilter = $('#blog-filters #blogIssue'),
    blogYearFilter = $('#blog-filters #blogYear'),
    resetFilters = $('#blog-filters #blogReset'),
    paginationHolder = $('#sfc-blog-pagination');

blogCatFilter.on('change', function() {
    gatherVars();
});

blogIssueFilter.on('change', function() {
    gatherVars();
})

blogYearFilter.on('change', function() {
    $('.issues').removeClass('d-none');
    gatherVars();
})

resetFilters.on('click', function() {
    filterReset();
});

paginationHolder.on('click', 'a', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-disabled')) {
        page = $(this).data('p');
        gatherVars(page);
    }
});

function filterReset() {
    $('.issues').addClass('d-none')
    blogCatFilter.val('');
    blogIssueFilter.val('');
    blogYearFilter.val('');
    gatherVars();
}


function gatherVars(page = 1) {
    var cat, issue, year;
    cat = blogCatFilter.find('option:selected').data('id');
    issue = blogIssueFilter.find('option:selected').data('id');
    year = blogYearFilter.find('option:selected').data('id');
    triggerFilter(cat, issue, year, page);
}

if($('#sfc-blog-pagination').length) {
    var prefiltered = helpers.getQueryVariable('category');

    if(prefiltered) {
        blogCatFilter.find('option').each(function() {
            var label = helpers.convertToSlug($(this).text());

            if(label == prefiltered) {
                $(this).prop('selected', true);
            }
        });
    }

    gatherVars();
}


function triggerFilter(cat, issue, year, page = 1) {
    $.post('/wp-admin/admin-ajax.php', {
        action: "blog_filter",
        cat: cat,
        year: year,
        issue: issue,
        page: page,
    }).done(function(r) {

        if (r.success) {
            // console.log(r);
            var posts = r.data.posts,
                pagination = r.data.pagination;
                
            $('#blog-filter-results').html(posts);
            paginationHolder.html(pagination);
        } else {
            // console.log(r);
            var pagination = r.data.pagination;
            $('#blog-filter-results').html('<div class="no-posts col-12"><h2>No articles found</h2></div>');
            paginationHolder.html(pagination);
        }
    });
}