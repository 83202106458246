(function($) {

    var toTopButton = $('.js-top');

    toTopButton.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop:0
        }, '300');
    });

})(jQuery);