// Create the script tag, set the appropriate attributes
var APIKey = $('#events-map').data('api'),
    defLat = parseFloat($('#events-map').data('lat')),
    defLng = parseFloat($('#events-map').data('lng')),
    zoom = $('#events-map').data('zoom');

if (APIKey) {
    var mapScript = document.createElement('script');
    mapScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + APIKey + '&callback=initMap';
    mapScript.defer = true;
    // Append the 'script' element to 'head'
    document.head.appendChild(mapScript);

    window.initMap = function() {
        // JS API is loaded and available
        map = new google.maps.Map(document.getElementById('events-map-holder'), {
            center: { lat: defLat, lng: defLng },
            zoom: zoom,
            disableDefaultUI: true,
            gestureHandling: 'none',
            zoomControl: false
        });
    };
}


// Event Listing Filtering
var eventTypeFilter = $('#event-filters #eventType'),
    eventDateFilter = $('#event-filters #eventDate'),
    resetFilters = $('#event-filters #eventsReset'),
    paginationHolder = $('#events-pagination'),
    filterNameDisplay = $('#filterName');

eventTypeFilter.on('change', function() {
    gatherVars();
});

eventDateFilter.on('change', function() {
    gatherVars();
})

resetFilters.on('click', function() {
    filterReset();
});

paginationHolder.on('click', 'a', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-disabled')) {
        page = $(this).data('p');
        gatherVars(page);
    }
});

function filterReset() {
    eventTypeFilter.val('');
    eventDateFilter.val('');

    gatherVars();
}


function gatherVars(page = 1) {
    var type, date;
    type = eventTypeFilter.find('option:selected').data('id');
    date = eventDateFilter.find('option:selected').data('month');

    triggerFilter(type, date, page);
}

if($('#event-filters').length) {
    gatherVars();
}

function triggerFilter(type, date, page = 1) {
    $.post('/wp-admin/admin-ajax.php', {
        action: "event_filter",
        type: type,
        date: date,
        page: page,
    }).done(function(r) {

        if (r.success) {
            $('#event-filter-results').empty();
            // console.log(r);
            var posts = r.data.posts,
                filterName = r.data.filterName,
                pagination = r.data.pagination;

            for (var i = 0; i < posts.length; i++) {
                var post = posts[i];
                var html = '<article class="col-sm-6 col-xxl-4">';
                html += '<a href="' + post.permalink + '" class="card-article">';
                html += '<div class="img-container has-overlay">';
                html += post.featuredImage;
                html += '</div>';
                html += '<div class="card-body">';
                html += '<div class="card-date">';
                html += '<span class="card-month">' + post.eventMonth +'</span>'
                html += '<span class="card-day color-green">' + post.eventDate + '</span>';
                html += '</div>';    
                html += '<div class="card-content">'
                html += '<h3 class="card-title color-black mb-3">' + post.title + '</h3>';
                if(post.locationName && post.locationName.length) {
                    html += '<p>' + post.locationName + '</p>';
                }
                html += '<span class="btn btn-link btn-link-arrow btn-blue btn-arrow-next">Learn More</span>';
                html += '</div>';
                html += '</div>';
                html += '</a>';
                html += '</article>';

                $('#event-filter-results').append(html);
            }
            filterNameDisplay.html(filterName);
            paginationHolder.html(pagination);

        } else {
            // console.log(r);
            var pagination = r.data.pagination,
                filterName = r.data.filterName;
            filterNameDisplay.html(filterName);
            $('#event-filter-results').html('<div class="no-posts col-12"><h2>No events found</h2></div>');
            paginationHolder.html(pagination);
        }
    });

}