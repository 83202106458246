/**
 * App
 * ------
 * Specify vendor and app specific js files to be compiled
 */

// Vendor Requires
require('./vendor/collapse');
require('./vendor/dropdown');
require('./vendor/modal');
require('./vendor/tab');
require('./vendor/picturefill');
require('./vendor/slick.min.js');
require('./vendor/jquery.countup.min.js');
require('./vendor/waypoint');
require('./vendor/waypoint-animate');
require('./vendor/jquery.actual.min.js');
require('./vendor/blend-mode');
require('./vendor/responsive-tabs');
require('./vendor/jquery.fittext');

// App Requires
require('./app/helpers');
require('./app/counter');
require('./app/primary-nav.js');
require('./app/sfd-primary-nav.js');
require('./app/sliders.js');
require('./app/tabs.js');
require('./app/to-top.js');
require('./app/filters.js');
require('./app/map.js');
require('./app/sticky-nav');
require('./app/events');
require('./app/overlays');
require('./app/sfc-blog-filters');
require('./app/sfc-events');
require('./app/fsf-blog-filters');
require('./app/sfd-blog-filters');
require('./app/sfc-job-listing');

//require('./app/event-sidebar');