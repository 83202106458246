(function($) {

    var siteHeader = document.getElementById('primaryNavbar');
    var fixed = siteHeader.offsetTop;
    var fixedOffset = siteHeader.offsetTop;
    var siteContent = $('#siteContent');
    var siteHeaderHeight = $('#primaryNavbar',"#FSFprimaryNavbar").outerHeight();

    function stickyHeader() {
        // Toggle scrolled class
        if (window.pageYOffset > fixedOffset) {
            $(siteHeader).addClass('is-fixed');
            siteContent.css('padding-top', siteHeaderHeight + 'px');

        } else {
            $(siteHeader).removeClass('is-fixed');
            siteContent.css('padding-top', '');
        }

    }

    window.onscroll = function() {
        stickyHeader();
    };

}(jQuery));
