// Event Listing Filtering
var eventTypeFilter = $('#sfc-event-filters #eventType'),
    eventDateFilter = $('#sfc-event-filters #eventDate'),
    resetFilters = $('#sfc-event-filters #eventsReset'),
    paginationHolder = $('#sfc-events-pagination');

eventTypeFilter.on('change', function() {
    gatherVars();
});

eventDateFilter.on('change', function() {
    gatherVars();
})

resetFilters.on('click', function() {
    filterReset();
});

paginationHolder.on('click', 'a', function(e) {
    e.preventDefault();
    if (!$(this).hasClass('is-disabled')) {
        page = $(this).data('p');
        gatherVars(page);
    }
});

function filterReset() {
    eventTypeFilter.val('');
    eventDateFilter.val('');

    gatherVars();
}


function gatherVars(page = 1) {
    var type, date;
    type = eventTypeFilter.find('option:selected').data('id');
    date = eventDateFilter.find('option:selected').data('month');

    triggerFilter(type, date, page);
}

if($('#sfc-event-filters').length) {
    var prefiltered = helpers.getQueryVariable('type');

    if(prefiltered) {
        eventTypeFilter.find('option').each(function() {
            var label = helpers.convertToSlug($(this).text());

            if(label == prefiltered) {
                $(this).prop('selected', true);
            }
        });
    }

    gatherVars();
}

function triggerFilter(type = "", date = "", page = 1) {
    $('#sfc-event-filter-results').html('<div class="col-12"><h2 class="heading-2 text-center">LOADING...</h2></div>');
    $.post('/wp-admin/admin-ajax.php', {
        action: "event_filter",
        type: type,
        date: date,
        page: page,
    }).done(function(r) {

        if (r.success) {
            // console.log(r);
            var posts = r.data.posts,
                pagination = r.data.pagination;

            $('#sfc-event-filter-results').html(posts);
            
            paginationHolder.html(pagination);

        } else {
            // console.log(r);
            var pagination = r.data.pagination;
            $('#sfc-event-filter-results').html('<div class="no-posts col-12"><h2 class="heading-2 text-center">No events found</h2></div>');
            paginationHolder.html('');
        }
    });

}