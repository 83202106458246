(function($) {

    $('.sub-toggle').on('click', function(e) {
        $this = $(this);
        menuToggle(e, $this);
    });
    $('.sub-toggle').on('mouseover', function(e) {
        if($(window).width() > 1200) {
            $this = $(this);
            menuToggle(e, $this);
        }
    });

    $('.nav-item:not(.has-sub)').on('mouseover', function(e) {
        if($(window).width() > 1200) {
            $this = $(this);
            $(this).siblings().find('.is-active').removeClass('is-active');
            if($(this).parent().hasClass('dropdown-nav')) { // Is a top-level nav item
                $('.nav-panel').removeClass('is-open');
                $('#primaryNavbar .dropdown-body').removeClass('sub-open');
            }
            if(!$(this).closest('.sub-panel').length) { // Is a sub-nav nav item
                $('.sub-panel').removeClass('is-open');
            }
        }
    });

    $('.back-btn').on('click', function(e) {
        e.stopPropagation();
        $this = $(this);

        $this
            .closest('.nav-panel')
            .removeClass('is-open');
    });


    $('#primaryNavbar').on('hidden.bs.dropdown', function() {
        setTimeout(function() {
            $('#primaryNavbar .nav-panel').removeClass('is-open');
            $('#primaryNavbar .dropdown-body').removeClass('sub-open');
            $('body').removeClass('no-scroll');
        }, 200);
    });

    // Add no-scroll class to body when primary dropdown opens
    $('#primaryNavbar').on('shown.bs.dropdown', function() {
        setTimeout(function() {

            if($(window).width() < 1200) {
                $('body').addClass('no-scroll');
            } else {
                $('body').removeClass('no-scroll');
            }
        }, 200);

        // Scroll menu up on mobile when opened
        if($(window).width() < 1200) {
            $('html, body').animate({
                scrollTop: $("#primaryNavbar").offset().top
            });
        }
    });


}(jQuery));

function menuToggle(e, $this) {
    e.stopPropagation();

    var panelTarget = $this.attr('data-target');

    if($this.hasClass('parent-link')) {
        $('.parent-link').removeClass('is-active');
        $('.sub-link').removeClass('is-active');
        $('.nav-panel').removeClass('is-open');
    } else {
        $('.sub-link').removeClass('is-active');
        $('.sub-panel').removeClass('is-open');
    }
    $this.addClass('is-active');
    $('#' + panelTarget).addClass('is-open');
    $('#primaryNavbar .dropdown-body').addClass('sub-open');
}



(function($) {
    var maxHeight = 0;
    dropdownNav = $('#primaryNavbar .dropdown-subnav');

    // Add max height to each element
    dropdownNav.each(function() {
        maxHeight = maxHeight > jQuery(this).height() ? maxHeight : jQuery(this).height() + 40;
    });

    // Add max height to dropdown-subnav
    if($(window).width() >= 1185) {
        dropdownNav.each(function() {
            jQuery(this).height(maxHeight);
        });

        $('#primaryNavbar .dropdown-navigation').css('min-height', maxHeight);
    }
}(jQuery));

(function($) {
    var maxHeight = 0;
    dropdownNav = $('#primaryNavbar.fsf-primary-navbar .dropdown-subnav');

    // Add max height to each element
    dropdownNav.each(function() {
        maxHeight = maxHeight > jQuery(this).outerHeight() ? maxHeight : jQuery(this).height();
    });

    // Add max height to dropdown-subnav
    if($(window).width() >= 1185) {
        dropdownNav.each(function() {
            jQuery(this).height(maxHeight);
        });

        $('#primaryNavbar.fsf-primary-navbar .dropdown-navigation').css('min-height', maxHeight + 25);
    }
}(jQuery));