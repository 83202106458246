// Create the script tag, set the appropriate attributes
var APIKey = $('#map-block').data('api'),
    defLat = parseFloat($('#map-block').data('lat')),
    defLng = parseFloat($('#map-block').data('lng'));

if (APIKey) {
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + APIKey + '&callback=initMap';
    script.defer = true;
    // Append the 'script' element to 'head'
    document.head.appendChild(script);

    $('#map-block').each(function() {
        var MaxHeight = 0
        $(this).find('.panel-contents').each(function() {
            if($(this).actual('outerHeight') > MaxHeight) {
                MaxHeight = $(this).actual('outerHeight');
            }
        });
        $(this).height(MaxHeight+100);
    });

    // Attach your callback function to the `window` object
    window.initMap = function() {
        // JS API is loaded and available
        map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: defLat, lng: defLng },
            zoom: 12,
            disableDefaultUI: true,
            gestureHandling: 'none',
            zoomControl: false
        });

        panelHover(map);
        panelClick();
    };
}

function panelHover(map) {
    var mapPanel = $('.map-panel');

    mapPanel.hover(
        function() {
            var newLat = parseFloat($(this).data('lat')),
                newLng = parseFloat($(this).data('lng'));

            map.setCenter({ lat: newLat, lng: newLng });
            map.setZoom(13);
        },
        function() {
            map.setCenter({ lat: defLat, lng: defLng });
            map.setZoom(12);
        },
    );
}

function panelClick() {
    var mapPanel = $('.map-panel'),
        panelClose = $('.panel-close');

    mapPanel.on('click', function() {
        var id = $(this).data('id'),
            parent = $(this).parents('.map-block');

        parent.find('.slide-panel').addClass('active');
        parent.find('.slide-panel .panel-contents-' + id).addClass('active');
    });

    panelClose.on('click', function(e) {
        e.preventDefault();
        var parent = $(this).parents('.map-block');

        parent.find('.slide-panel').removeClass('active');
        parent.find('.slide-panel .panel-contents').removeClass('active');
    });
}