(function($) {
    var numSlick = 0;

    // Initialize featured card slider
    $('.slider-features').each( function() {
        numSlick++;

        $(this).addClass( 'slider-' + numSlick ).slick({
            accessibility: false,
            adaptiveHeight: true,
            appendArrows: $(this).closest('.slider-container').find('.paging-arrows'),
            appendDots: $(this).closest('.slider-container').find('.paging-dots'),
            dots: true,
            infinite: false,
            slide: '.slider-item',
            slidesToScroll: 1,
            slidesToShow: 1.7,
            responsive: [
            {
                breakpoint: 768,
                settings: {
                    appendArrows: $(this).closest('.slider-container').find('.paging-arrows'),
                    appendDots: $(this).closest('.slider-container').find('.paging-dots'),
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    appendArrows: $(this).closest('.slider-container').find('.paging-arrows'),
                    appendDots: $(this).closest('.slider-container').find('.paging-dots'),
                    slidesToShow: 1,
                }
            }
            ]
        });
    })
    .on('setPosition', function (event, slick, reinit) {
        slick.$slides.css('min-hheight', slick.$slideTrack.height() + 'px');
    });

    // Initialize slider with single image
    $('.slider-figure').each( function() {
        numSlick++;

        $(this).addClass( 'slider-' + numSlick ).slick({
            adaptiveHeight: true,
            accessibility: false,
            appendDots: $(this).find('.paging-dots'),
            appendArrows: $(this).find('.paging-arrows'),
            dots: true,
            fade: true,
            infinite: false,
            slide: '.slider-item',
            slidesToScroll: 1,
            slidesToShow: 1,
        });
    });

    // Equal height slides*/
    function equalHeightSlider(){
        $slickSlider = $('.slider-eh');
        $slickSlider.find('.slider-item').height('auto');

        var slickTrack = $slickSlider.find('.slick-track');
        var slickTrackHeight = $(slickTrack).height();

        $slickSlider.find('.slider-item').css('height', slickTrackHeight + 'px');
    }

    // Set height on init
    $('.slider-eh').on('setPosition', function() {
        equalHeightSlider();
    });

    // Set height on resize
    $(window).on('resize', function(e) {
        equalHeightSlider();
    });

})(jQuery);
